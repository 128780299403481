.page-break {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;
    background-color: black;
    color: white;
    text-align: center;
}

.page-break h1 {
    font-size: 2rem;
}

