footer { 
    background-color: black;
    width: 100%;

    margin: 0;
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 1rem;

}

.footer-container__logo img {
    width: 250px;
    margin: 1rem;
}

.footer-container__disclosure p{
    color: white;
    font-size: .8rem;
    line-height: 1.5rem;

}

@media (min-width: 580px) {
    .footer-container {
        flex-direction: row;
    }
}