.registration-form {
    background: rgb(227,227,227);
    background: linear-gradient(0deg, rgba(227,227,227,1) 0%, rgba(210,210,210,1) 49%, rgba(230,230,230,1) 100%);
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    border: 8px solid rgb(190, 190, 190);
    box-shadow: 1px 1px 5px rgb(0 0 0 / 50%);
}

.registration-form h2 {
    margin: 1rem 0 0.5rem 0;
    line-height: 2.5rem;
    font-size: 1.5rem;
    font-weight: bold;
}

.registration-form__sub-title {
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 0;
    margin-top: 0;
}