form .form-control input{
    width: 100%;
    padding: 1rem;
    border: 1px solid lightgray;
    border-radius: 0.3rem;
    outline: none;
    font: inherit;
    margin: 0.5rem 0;
}

.form-control.invalid input {
    border: 2px solid red;
    background-color: salmon;
}