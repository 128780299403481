@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;1,300&display=swap");

.wrapNavy {
  background: #05204a;
  color: white;
  position: relative;
}

.wrapWht {
  background: #e4e4e4;
  color: black;
}

.mainSection:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: url("../../assets/imgs/newport-beach.jpg");
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: cover;
  }

  .footerSection p {
    color: gray;
  }
  
  .column {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

@media screen and (min-width: 992px) {
    h1.large {
        font-size: 4rem;
     }
     .container{
      padding: 4rem 0rem;
  }   
}