@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;1,300&display=swap");

.wrapNavy {
  background: #2c3341;
  color: white;
  position: relative;
}

.wrapWht {
  background: #e4e4e4;
  color: black;
}

.mainSection:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.05;
  background: url("../../assets/imgs/dunia-plaza.jpg");
  background-repeat: no-repeat;
  background-position: 50% 55%;
  background-size: cover;
}

.mainSection .container {
  position: relative;
  /* z-index: 999; */
}

.mainSection .container .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mainSection h1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, .5);
}

.mainSection p {
  text-shadow: 1px 1px 5px rgba(0, 0, 0, .5);
}

.mainImg {
  z-index: 999;
}

.mainImg img {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  
}

.preparerMenu {
  background-color: white;
  color: rgb(44, 44, 44);
  margin-top: .5rem;
  z-index: 999;
  border-radius: .5rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);
  padding: .25rem;
}

.preparerMenu > ul > li > button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
}

.preparerMenu div:nth-child(1) {
  color: #333333;
}

.preparerMenu div:nth-child(2):hover {
  cursor: pointer;
}

.preparerMenu ul {
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.preparerMenu ul > li {
  list-style: none;
  padding: .5rem; 
  margin: 0;
  border-radius: .25rem;
}

.preparerMenu ul > li:hover {
  background-color: #dddddd;
}

.preparerMini {
  display: flex; 
  gap: .5rem;
  align-items: center;
}

.preparerMini img {
  width: 2rem;
  border-radius: 50%;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}

.retirementWorthLivingSection .container,
.eventSection .container,
.ourServicesSection .container {
  position: relative;
  z-index: 999;
}

.retirementWorthLivingSection h1,
.ourServicesSection h1,
.location h1 {
  color: #333333;
}

.retirementWorthLivingSection img,
.retirementWorthLivingSection p,
.retirementWorthLivingSection h1,
.eventSection img,
.eventSection p,
.eventSection h1 {
  z-index: 999;
  position: relative;
}

.mainSection h1,
.retirementWorthLivingSection h1,
.ourServicesSection h1,
.eventSection h1 {
  margin-top: 1rem;
}

.worthLivingMainImg img {
  width: 100%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .3);
}

.worthLivingMainImg {
  background-color: rgb(53, 124, 224);
  border-radius: 0.25rem;
}

.eventsMainImg img {
  width: 100%;
}

.worthLivingImages,
.eventImages {
  margin-top: 1.5rem;
}

.eventImages>div>img:hover,
.worthLivingImages>div>img:hover {
  cursor: pointer;
}

.worthLivingImages div:nth-child(3),
.eventImages div:nth-child(3) {
  margin-bottom: 1.5rem;
}

.serviceList {
  gap: 2rem;
  display: flex;
  flex-direction: column;
  background-color: rgb(53, 124, 224);
  color: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  border-radius: .5rem;
}

.ourServicesFeatures {
  z-index: 4;
}

.seeMore a:link {
  text-decoration: none;
  color: inherit;
}

.seeMore a:visited {
  text-decoration: none;
  color: inherit;
}

.testimonialSection .reviews {
  display: flex;
  gap: 1rem;
}

.mainSection .reviews {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.mainSection .reviews > div > img {
  width: 100px
}

.footerSection p {
  color: gray;
}

.two-columns__col-one,
.two-columns__col-two {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 0;
  padding: 1rem;
}

.two-columns__row-one,
.two-columns__row-two,
.two-columns__row-three {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  margin: 0;
  padding: 1rem;
}

.two-columns__row-two {
  padding: 0 1rem;
}


.section-event-details__row {
  display: flex;
  flex-direction: column;
  margin: 0;
  /* background-color: rgb(218, 218, 218); */
  /* border-radius: 0.5rem; */
  /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); */
}

.column {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media screen and (min-width: 992px) {

  .mainSection .container::after {
    content: " ";
    background: url("../../assets/imgs/corner-cap.png") no-repeat;
    height: 200px;
    width: 50%;
    top: 2rem;
    right: 2rem;
    position: absolute;
    z-index: 1;
  }
  
  h1.large {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  .mainSection .container {
    padding: 1.5rem 0rem;
  }

  .ctaArrow {
    position: absolute;
    width: 10rem;
    top: 4rem;
    right: -1rem;
    z-index: 999;
  }

  .mainSection .container::after {
    content: " ";
    background: url("../../assets/imgs/corner-cap.png") no-repeat;
    height: 200px;
    width: 50%;
    top: 1.5rem;
    right: 0;
    position: absolute;
    z-index: 1;
  }

  .home h1 {
    margin-bottom: 1.5rem;
    letter-spacing: -1.5px;
  }

  .crossIcon {
    margin-top: 2rem;
  }

  .crossIconExt {
    height: 57px;
    position: absolute;
    left: -6rem;
    top: 4rem;
  }

  .worthLivingMainImg img {
    margin: 2rem;
  }

  .worthLivingImages {
    margin-top: 2rem;
  }

  .worthLivingMainImg img {
    margin: 2rem;
    width: 100%;
  }

  .workingWithClients {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .ourServicesSection .container::before {
    content: " ";
    position: absolute;
    border: 2px solid rgb(179, 179, 179);
    height: 80%;
    left: 0;
    right: 0;
    margin: 6rem 0rem 6rem 5rem;
    z-index: 2;
    top: 0;
  }

  .two-columns__col-one {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .two-columns__col-two {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .container {
    padding: 4rem 0rem;
  }

}