.checkup p {
    color: gray;
}
.wrapBlk {
    background: black;
    color: white;
}
.wrapWht {
    background: #e4e4e4;
    color: black;
}
.mainSection::before {
    content: '';
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: url("../../../assets/imgs/logo-large-bg.png");
    background-repeat: no-repeat;
    opacity: 0.8;
}
.savingsBarSection .container::after {
    content: ' ';
    background: url("../../../assets/imgs/tachometer-low-opacity.png") no-repeat;
    height: 619px;
    width: 95%;
    top: 3rem;
    position: absolute;
    z-index: 3;
}
.tachSection {
    background: linear-gradient(to bottom,
    #e4e4e4 10%, black 10% 30%, #e4e4e4 30% 100%); 
    margin-bottom: 5rem;
    position: relative;
}
.tachSection .container::before {
    content: " ";
    position: absolute;
    border: 3px solid #a1a1a1;
    height: 84%;
    top: 8.5rem;
    left: 0;
    right: 0;
    margin: 1.5rem;
}
.dotsChartSection {
    border: none;
}
.savingsBarSection p {
    font-family: 'Crimson', Times;
}
.peopleLikeUsSection .container, .savingsBarSection .container, .tachSection .container {
    position: relative;
    z-index: 999; 
}
.peopleLikeUsSection img, .peopleLikeUsSection p, .peopleLikeUsSection h1, .peopleLikeUsSection h1{
    z-index: 999; 
    position: relative
}
.peopleLikeUsSection .container::after  {
    content: ' ';
    background: url("../../../assets/imgs/corner-cap.png") no-repeat black;
    height: 164px;
    width: 90%;
    top: 1.5rem;
    position: absolute;
    z-index: 1;
}
@media screen and (min-width: 992px) {

    h1.large {
        font-size: 4rem;
     }
    .tachSection {
        background: linear-gradient(to bottom,
        #e4e4e4 30%, black 20% 70%, #e4e4e4 70% 100%);
        margin-bottom: 0px;
    }
    .tachSection .container::before {
        content: " ";
        position: absolute;
        border: 3px solid #a1a1a1;
        height: 116%;
        left: 0;
        right: 0;
        margin: 0rem 2rem 0rem 12rem;
        /* z-index: 2; */
        top: -2.5rem;
    }    
    .tachometer {
        align-items: center;
        justify-content: center;
        display: flex;
    }    
    .dotsChart {
        align-items: center;
        justify-content: center;
        display: flex;
    }   
    .savingsBarSection {
        background-position: center right;
    }
    .savingsBarFeatures {
        z-index: 4;
        background: #e4e4e4;
    }
    .savingsBarSection .container::before {
        content: " ";
        position: absolute;
        border: 2px solid black;
        height: 83%;
        left: 0;
        right: 0;
        margin: 0rem 0rem 0rem 7rem;
        z-index: 2;
        top: 4rem;
    }
    .savingsBarSection .container::after {
        content: ' ';
        background: url("../../../assets/imgs/tachometer-low-opacity.png") no-repeat;
        height: 619px;
        width: 668px;
        top: 3rem;
        position: absolute;
        z-index: 3;
        right: 0;
        right: -2rem;
    }
    .improveScoreSection {
        background: black;
        background: url("../../../assets/imgs/improve-score-background.png") black;
        background-repeat: no-repeat; 
        background-attachment: fixed;
        background-position: center;
    }   
    .peopleLikeUsSection .container::before {
        content: " ";
        position: absolute;
        border: 3px solid #a1a1a1;
        height: 85%;
        left: 0;
        right: 0;
        margin: 0rem 0rem 0rem 7rem;
        z-index: 2;
    }
    .peopleLikeUsSection .container::after  {
        content: ' ';
        background: url("../../../assets/imgs/corner-cap.png") no-repeat black;
        height: 212px;
        width: 650px;
        top: 3rem;
        position: absolute;
        z-index: 1;
        right: -1.2rem;
    }
    .textRight {
        text-align: right;
    }
    .crossIcon {
        margin-top: 2rem;
    }
    .crossIconExt {
        height: 57px;
        position: absolute;
        left: -6rem;
        top: 4rem;
        
    }
    .retirementSunset img{
        margin: 2rem;
    }    

}