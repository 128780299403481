.NewsletterModal {
    padding: .25rem;
    height: 100%;
    background-color: rgba(0, 0, 0, .05);
}

.column {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.left {
    height: inherit;
}

.left img {
    width: 100%;
    height: 100%;
}

.right {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.right h1 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: .75rem;
}

.right p {
    color: gray;
    font-size: .85rem;
    margin: 0;
    flex: 2;
}

.right form {
    flex: 2;
}

.closeModal {
    text-align: right;
    position: absolute;
    right: 0;
    padding: .5rem;
    z-index: 999;
}

.closeModal svg {
    padding: .25rem;
    border-radius: .25rem;
}

.closeModal svg:hover {
    background-color: rgba(0, 0, 0, .08);
    cursor: pointer;
}

.wrapNavy {
    background: #05204a;
    color: white;
    position: relative;
}

.wrapWht {
    background: #e4e4e4;
    color: black;
}

.right input[type=text],
.right input[type=email],
textarea {
    width: 100%;
    padding: 1rem;
    border: none;
}

.right input[type=text]:focus,
.right input[type=email]:focus,
textarea:focus {
    outline: none;
}

.footer {
    color: gray;
    font-size: .8rem;
    text-align: center;
}

@media screen and (min-width: 992px) {
    .right h1 {
        font-size: 2.5rem;
        line-height: 2.5rem;
        margin-bottom: .75rem;
    }
}