.map-container {
    width: 100%;
    height: 100%;
    height: 350px;
}

.marker-info__marker {
    color: white;
    background: rgba(8, 8, 8, 0.8);
    padding: 10px 10px;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border-radius: 100%;
    transform: translate(-50%, -50%);
}
