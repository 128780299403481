.section-event-details p {
    line-height: 2rem;
    margin: 0;
}

.section-event-details {
    padding: 3rem 0;;
}

.section-event-details__two-columns {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.two-columns__col-one, .two-columns__col-two  {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin: 0;
    padding: 1rem;
}

.section-event-details__row {
    display: flex;
    flex-direction: column;
    margin: 0;
    /* background-color: rgb(218, 218, 218); */
    /* border-radius: 0.5rem; */
    /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); */
}

.two-columns__row-one, .two-columns__row-two, .two-columns__row-three  {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    margin: 0;
    padding: 1rem;
}

.two-columns__row-two {
    padding: 0 1rem;
}

.section-event-details__row img {
    min-width: 100px
}

.section-event-details__company-details {
    list-style: none;
    text-decoration: none;
    padding: 0;
}

.section-event-details__company-details h1 {
    color: black;
}

.section-event-details__company-details li {
    margin: 1rem 0;
    padding: 0;
    color: gray;
}

@media (min-width: 992px) {
    .section-event-details__two-columns {
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        margin: 2.5rem 0;
    }

    .two-columns__col-one {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .two-columns__col-two {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

}