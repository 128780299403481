.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 998;
    animation: openModal 300ms forwards;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    box-sizing: border-box;
    padding: 2rem;
    /* height: 100%; */
}
  
  @keyframes openModal {
    0% {
        opacity: 0;
        
    }
    100% {
        opacity: 1;
    }
  }
