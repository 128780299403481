.main h1, h2, h3, h4, h5, p {
    font-family: helvetica;
}

.main {
    flex: 1;

}

.footer {
    flex-shrink: 0;
}

.section-video-cta {
    padding: 1.5rem 0;
    text-align: center;
}

.section-video-cta__headings h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
}

.section-video-cta__headings {
    font-weight: normal;
    font-size: 1rem
}

.section-video-cta__two-columns{
    display: flex;
    flex-direction: column;
    margin: 0;
}

.two-columns__col-one {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin: 0;
    padding: 1rem;
}

.two-columns__col-two {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    padding: 1rem;
}

.two-columns__col-two h2{
    font-size: 1.5rem
}

@media (min-width: 992px) {
    .section-video-cta {
        padding: 3rem 0;
        text-align: center;
    }
    .section-video-cta__two-columns {
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        margin: 2.5rem 0;
    }
    .two-columns__col-one {
        width: 66.66%;
        margin: 0;
        padding: 0;
    }
    .two-columns__col-two {
        display: flex;
        flex-direction: column;
        margin: 0;
        width: 33.34%;
        padding: 0;
    }
    .two-columns__col-two h2 {
        font-size: 1.8rem;
    }
    .section-video-cta__headings h1 {
        font-size: 3.5rem;
    }
    .section-video-cta__headings {
        font-size: 1.5rem;
    }
}

