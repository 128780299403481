.custom-form-control {
    text-align: left;
    margin: 0.5rem 0;
}

.custom-form-control input[type='checkbox']:not(:checked),
.custom-form-control input[type='checkbox']:checked {
    opacity: 0;
    position: absolute;
}

.custom-form-control input[type='checkbox']:not(:checked) + label,
.custom-form-control input[type='checkbox']:checked + label{
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1.7;
    font-size: 1.05em;
    padding-left: 2rem;
    cursor: pointer;
}

.custom-form-control input[type='checkbox']:not(:checked) + label:before,
.custom-form-control input[type='checkbox']:checked + label:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-color: white;
    border-radius: 0.2rem;
    border: 2px solid gray;
}

.custom-form-control input[type='checkbox']:not(:checked) + label:after,
.custom-form-control input[type='checkbox']:checked + label:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1rem;
    height: 1rem;
    margin-top: .25rem;
    margin-left: .25rem;
    background-color: rgb(131, 131, 131);
    transition: all .3s
}

.custom-form-control input[type='checkbox']:not(:checked) + label:after{
    opacity: 0;
}

.custom-form-control input[type='checkbox']:checked + label:after{
    opacity: 1;
}
