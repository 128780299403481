.seminar-tickets {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-transform: uppercase;
    border: 1rem solid lightgray;
    border-radius: 0.5rem;
    position: relative;
}

.seminar-tickets::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    padding-top: 1rem;
    /* background-color: rgba(0, 0, 0, 0.5) */
}

.seminar-tickets-overlay {
    padding: 1.5rem;
    color: rgb(184, 29, 29);
    background-color: rgb(240, 240, 240);
    border: 5px solid rgb(184, 29, 29);
}

.seminar-tickets-overlay h2 {
    font-size: .8rem;
    font-weight: bold;
    line-height: 1.3rem;
}

.seminar-tickets-overlay ul{
    list-style: none;
}

.seminar-tickets-overlay ul li{ 
    margin: 0.5rem;
}

.seminar-tickets div{
    transition: all 5s;
}

.seminar-tickets__ticket {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    background-color: white;
    border: 1px solid lightgray;
    box-sizing: border-box;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 50%);
}

.ticket__admissions {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5rem solid black;
    margin: 1rem;
    padding: 1rem;
}

.admissions__admit-amount {
    font-size: 1.3rem;
    font-weight: bold;
}

.ticket__info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0.5rem;
}

.ticket__registrant-info {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.registrant-info__event-title {
    font-size: 1rem;
}

.registrant-info__guest-name {
    font-size: 1.5rem;
    font-weight: bold;
}

.registrant-info__guest-name {
    font-size: 1.5rem;
    font-weight: bold;
}

.registrant-info__live-event {
    font-size: .8rem;
    color: gray;
    font-weight: bold;
}

.ticket__event-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.event-details__event-location {
    color: gray;
}

.ticket__barcode {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px dotted gray;
}

.barcode__info {
    padding: 1rem;
    display: flex;
    flex-direction: column;
}


@media (min-width: 580px) {
    .seminar-tickets__ticket {
        flex-direction: row;
    }
    .admissions__admit-amount {
        transform: rotate(90deg)
    }
    .ticket__admissions {
        border-bottom: none;
        border-right: 0.5rem solid black;
    }
    .barcode__info {
        transform: rotate(90deg);
    }
    .ticket__barcode {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-left: 2px dotted gray;
    }
    .seminar-tickets-overlay h2 {
        font-size: 1rem;
        line-height: 2.5rem;
    }
    .seminar-tickets-overlay::before {
        height: 66%;
    }
}