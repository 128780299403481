.section-topics-covered {
    padding: 3rem 0;
}

.section-topics-covered h2{ 
    font-weight: bold;
}

.section-topics-covered__three-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2%;
    margin-bottom: 2rem;
}

.section-topics-covered__three-column > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1 1;
    background: rgb(227,227,227);
    background: linear-gradient(0deg, rgb(219, 219, 219) 0%, rgba(210,210,210,1) 49%, rgb(224, 224, 224) 100%);
    /* border: 1px solid rgb(219, 219, 219); */
    /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); */
    text-align: center;
    padding: 1rem;
}

.section-topics-covered__three-column div h2 {
    color: rgb(95, 95, 95);
}

.video-container {
    display: block !important;
    position: relative;
    padding-bottom: 56.25% !important;
    padding-top: 25px !important;
    height: 0;
    overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none !important;
    border: none;
}

@media (min-width: 580px) {
    .section-topics-covered__three-column {
        flex-direction: row;
    }
}