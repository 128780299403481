.header { 
    background-color: #000;
    width: 100%;
    padding: 0.5rem 0;
    margin: 0;
}

.navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

}

.navigation__logo img {
    max-width: 250px
}

.navigation__help {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
}

@media (min-width: 580px) {
    .navigation {
        flex-direction: row;
    }
}